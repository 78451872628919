export default {
  // Base url path
  baseUrl: 'https://mycaretrackhealth.com/nodeapp',
  // Endpoints
  loginEndpoint: '/api/v1/users/login',
  verifyAuthyCodeEndpoint: '/api/v1/users/verify',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',
  uploadFile: '/api/v1/lists/upload-files',
  getCounts: '/api/v1/lists/get-counts',
  getIpaTemplates: '/api/v1/lists/get-ipa-templates',
  getSelectedIpaTemplate: '/api/v1/lists/get-selected-ipa-template',
  setDefaultTemplate: '/api/v1/lists/set-default-template',
  getUsersLogged: 'https://beta.mycaretrackhealth.com/vista/reportes/loginRegister.php',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
